<template>
  <b-container fluid>
    <b-row class="p-3">
      <b-col>
        <b-row class="d-flex" align-v="center">
          <h1 class="mr-5">Workouts</h1>
          <!-- <div>
            <b-btn
              variant="primary"
              to="/admin/workouts/create"
            >Add New</b-btn>
          </div> -->
        </b-row>
      </b-col>
    </b-row>
    <b-row class="p-3" align-v="center">
      <b-col md="4">
        <b-nav pills>
          <b-nav-item
            v-for="option in timeOptions"
            :key="option.value"
            :active="option.value === filters.time_filter"
            @click="filters.time_filter = option.value"
          >{{ option.text }}</b-nav-item>
        </b-nav>
      </b-col>
      <b-col sm="12" md="8">
        <b-row>
          <b-col sm="4">
            <b-form-select
              v-model="filters.category"
              label="category"
              :options="categoryOptions"
            />
          </b-col>
          <b-col sm="4">
            <b-form-select
              v-model="filters.location_id"
              label="location"
              :options="locationOptions"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <div>
          <span>Viewing</span>
          <span> {{ items | numberItemsStart }} - {{ items | numberItemsEnd }}</span>
          <span> of</span>
          <span> {{ items.total || 0 }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center p-3">
      <b-table
        responsive
        striped
        hover
        :items="items.data"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ data.value.en }}
        </template>
        <template #cell(categories)="data">
          {{ data.value && data.value.length ? data.value[0].name.en : '' }}
        </template>
        <template #cell(location)="data">
          {{ data.value.name.en }}
        </template>
        <template #cell(time)="data">
          {{ data.item.start_time | formatDate }} - {{ data.item.end_time | formatDate }}
        </template>
        <template #cell(registration_start)="data">
          {{ data.value | formatDate }}
        </template>
        <template #cell(actions)="data">
          <b-row>
            <b-link
              style="margin-right:5px;"
              :href="`/admin/workouts/${data.item.id}`"
            >
              <i
                class="fas fa-edit fa-lg"
                title="Edit"
              />
            </b-link>
            <b-link
              v-if="data.item.status === 'cancelled'"
              style="margin-right:5px;"
              @click.prevent="(toUpdate = data.item.id) && updateItem('published')"
            >
              <i
                class="fas fa-play fa-lg"
                style="color:green;"
                title="Un-cancel"
              />
            </b-link>
            <b-link
              v-if="data.item.status !== 'deleted'"
              @click.prevent="showDeleteModal(data.item.id)"
            >
              <i
                class="fas fa-trash fa-lg"
                style="color:red;"
                title="Delete"
              />
            </b-link>
            <b-link
              v-else
              @click.prevent="(toUpdate = data.item.id) && updateItem('published')"
            >
              <i
                class="fas fa-undo fa-lg"
                style="color:red;"
                title="Undo Delete"
            />
            </b-link>
          </b-row>
        </template>
      </b-table>
      <b-pagination
        v-model="items.page"
        :total-rows="items.total"
        :per-page="items.perPage"
        size="md"
        @change="getItems"
      ></b-pagination>
      <b-modal
        id="deleteModal"
        ref="deleteModal"
        title="Confirm Delete?"
        @ok="updateItem('deleted')"
      >This workout will be deleted</b-modal>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import qs from 'qs'
import { numberItemsStart, numberItemsEnd } from '@/common'
import { makeGetRequest } from '@/util'

export default {
  name: 'LocationsList',
  data () {
    return {
      items: [],
      fields: [
        { key: 'id', label: 'Id', isRowHeader: true },
        { key: 'name', label: 'Name' },
        { key: 'location', label: 'Location' },
        { key: 'categories', label: 'Category' },
        { key: 'time', label: 'Start / End' },
        { key: 'registration_start', label: 'Registration' },
        { key: 'attendees', label: '# Signed Up' },
        { key: 'max_attendees', label: 'Signup Limit' },
        { key: 'members_only', label: 'Members Only' },
        'actions'
      ],
      timeOptions: [
        { value: 'future', text: 'Future' },
        { value: 'past', text: 'Past' },
        { value: '', text: 'All' }
      ],
      filters: {
        time_filter: 'future',
        location_id: null,
        category: null
      }
    }
  },
  filters: {
    formatDate (value) {
      return moment(value).format('DD MMM HH:mm')
    },
    numberItemsStart,
    numberItemsEnd
  },
  computed: {
    categoryOptions() {
      return [
        { text: 'Select Category', value: null },
        ...[...this.$root.categories].map(item => ({
          text: item.name.en,
          value: item.id
        }))
      ]
    },
    locationOptions() {
      return [
        { text: 'Select Location', value: null },
        ...[...this.$root.locations].map(item => ({
          text: item.name.en,
          value: item.id
        }))
      ]
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getItems()
      }
    }
  },
  methods: {
    async getItems (page = 1) {
      const baseUrl = `${this.$config.apiUrl}/events`
      const params = {
        page,
        status: 'published,cancelled',
        orderby: 'start_time',
        include: 'location,categories',
        is_member: 1
      }
      Object.entries(this.filters).forEach(([key, value]) => {
        if (value != null) {
          params[key] = value
        }
      })
      const url = baseUrl + qs.stringify(params, {
        addQueryPrefix: true,
        encode: false,
        skipNulls: true
      })
      const items = await makeGetRequest(url)
      this.items = items
    },
    showDeleteModal (id) {
      this.toUpdate = id
      this.$refs.deleteModal.show()
    },
    async updateItem (status) {
      const { toUpdate } = this
      if (!toUpdate) return
      try {
        await this.axios.put(`${this.$config.apiUrl}/events/${toUpdate}`, { status })
        const index = this.items.data.indexOf(
          this.items.data.find((i) => i.id === Number(toUpdate))
        )
        this.items.data[index].status = status
      } catch (error) {
        // console.error(error)
      }
    }
  },
  created () {
    this.getItems()
  }
}
</script>

<style>
</style>
